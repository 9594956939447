

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .buttonsRow {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; 
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 150px; 
    margin: 10px;
  }
  
  .button {
    padding: 15px;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    font-weight: bolder;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .count {
    font-size: 400%;
    margin-top: 10px;
    font-weight: bolder;
  }
  
  .image {
    width: 80%; 
    max-width: 500px; 
    margin-bottom: 10px;
  }
  
  .message {
    font-size: 400%;
    font-weight: bold;
    color: black;
    animation: flash 2s infinite;
    text-align: center;
    margin-bottom: 20px;
  }
  
  @keyframes flash {
    0%, 50% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0;
    }
  }
  
  
  @media (max-width: 768px) {
    .buttonsRow {
      flex-direction: column;
      align-items: center;
    }
  
    .buttonContainer {
      margin: 20px 0;
      max-width: 100%;
    }
  
    .button {
      width: auto; 
      max-width: 300px; 
    }
  
    .image {
      width: 90%; 
      max-width: 300px; 
    }
  }
  